export class User {
  public _id: string;
  public index: string;
  public guid: string;
  public firstName: string;
  public lastName: string;
  public title: string;
  public description: string;
  public email: string;
  public username: string;
  public password: string;
  public phone: string;
  public website: string;
  public address: string;
  public address1?: string;
  public address2?: string;
  public city?: string;
  public zip?: string;
  public country?: string;
  public language: string;
  public picture: string;
  public news: boolean;
  public notification: boolean;
  public advice: boolean;
  public panel: string;
  public company: string;
  public userType: string;
  public token: string;
  public pubDate: string;
  public active: boolean;
  public displayName: string;
  public role: string
}