import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      name: 'HOME',
      type: 'icon',
      tooltip: 'Home',
      icon: 'home',
      state: 'home'
    },
    {
      name: 'PROFILE',
      type: 'icon',
      tooltip: 'Profile',
      icon: 'person',
      state: 'profile/settings'
    },
    {
      name: 'DASHBOARD',
      type: 'dropDown',
      tooltip: 'Dashboard',
      svgIcon: 'ulb_server',
      state: 'dashboard',
      sub: [
        { name: 'DEFAULT', state: 'default' },
        { name: 'DEMANDES', state: 'demandes' }
      ]
    }, 
    {  
    name: 'LEADS',
    type: 'link',
    tooltip: 'Leads',
    icon: 'launch',
    state: 'leads'
    },
/*
    {
      name: 'COURSES',
      type: 'dropDown',
      tooltip: 'Courses',
      icon: 'backup',
      state: 'courses',
      sub: [
        { name: 'DOCUMENTS', state: 'documents' }
      ]
    }, 
  {
      name: 'INBOX',
      type: 'link',
      tooltip: 'Inbox',
      icon: 'inbox',
      state: 'inbox',
      badges: [{ color: 'primary', value: '4' }]
    },
    {
      name: 'CHAT',
      type: 'link',
      tooltip: 'Chat',
      icon: 'chat',
      state: 'chat',
      badges: [{ color: 'warn', value: '1' }]
    },
    {
      name: 'CALENDAR',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'calendar'
    },
    {
      name: 'TODO',
      type: 'link',
      tooltip: 'Todo',
      icon: 'center_focus_strong',
      state: 'todo/list'
    }, */
    {
      name: 'PROFILE',
      type: 'dropDown',
      tooltip: 'Profile',
      icon: 'person',
      state: 'profile',
     /* badges: [{ color: 'primary', value: '2' }], */
      sub: [
        { name: 'SETTINGS', state: 'settings' }
      ]
    },
    {  
      name: 'ADMIN',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'supervisor_account',
      state: 'admin',
      sub: [
        { name: 'USERS', state: 'users' }
      ]
    }

  ];

  separatorMenu: IMenuItem[] = [
    {
      type: 'separator',
      name: 'Custom components'
    },
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'dashboard'
    },
    {  
      name: 'LEADS',
      type: 'link',
      tooltip: 'Leads',
      icon: 'launch',
      state: 'leads'
    },
/*    
    {
      name: 'COURSES',
      type: 'dropDown',
      tooltip: 'Courses',
      icon: 'backup',
      state: 'courses',
      sub: [
        { name: 'DOCUMENTS', state: 'documents' }
      ]
    }, 
    {
      name: 'INBOX',
      type: 'link',
      tooltip: 'Inbox',
      icon: 'inbox',
      state: 'inbox'
    },
    {
      name: 'CHAT',
      type: 'link',
      tooltip: 'Chat',
      icon: 'chat',
      state: 'chat'
    },
    {
      name: 'CALENDAR',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'calendar'
    }, */
    {
      name: 'PROFILE',
      type: 'dropDown',
      tooltip: 'Profile',
      icon: 'person',
      state: 'profile',
      sub: [
        { name: 'SETTINGS', state: 'settings' }
      ]
    },     
    {  
      name: 'ADMIN',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'supervisor_account',
      state: 'admin',
      sub: [
        { name: 'USERS', state: 'users' }
      ]
    }

    
  ];

  plainMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'dashboard'
    },
    {  
      name: 'LEADS',
      type: 'link',
      tooltip: 'Leads',
      icon: 'launch',
      state: 'leads'
    },
/*    
    {
      name: 'COURSES',
      type: 'dropDown',
      tooltip: 'Courses',
      icon: 'backup',
      state: 'courses',
      sub: [
        { name: 'DOCUMENTS', state: 'documents' }
      ]
    }, 
    {
      name: 'INBOX',
      type: 'link',
      tooltip: 'Inbox',
      icon: 'inbox',
      state: 'inbox'
    },
    {
      name: 'CHAT',
      type: 'link',
      tooltip: 'Chat',
      icon: 'chat',
      state: 'chat'
    },
    {
      name: 'CALENDAR',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'calendar'
    },
*/   
    {
      name: 'PROFILE',
      type: 'dropDown',
      tooltip: 'Profile',
      icon: 'person',
      state: 'profile',
      sub: [
        { name: 'SETTINGS', state: 'settings' }
      ]
    },
    {  
      name: 'ADMIN',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'supervisor_account',
      state: 'admin',
      sub: [
        { name: 'USERS', state: 'users' }
      ]
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  //iconTypeMenuTitle = 'Frequently Accessed';
  iconTypeMenuTitle = '';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'separator-menu':
        this.menuItems.next(this.separatorMenu);
        break;
      case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
