import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { LocalStoreService } from "../../shared/services/local-store.service";


@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  public isAuthenticated;

  constructor(private router: Router, 
            private jwtAuth: JwtAuthService,
            private local: LocalStoreService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.isAuthenticated = this.local.getItem('isAuthenticated');

    if (this.isAuthenticated) {
      return true
    }
    this.router.navigate(['/sessions/signin']);
    return false;

    }



}
