import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

import { LocalStoreService } from "../../shared/services/local-store.service";

import { User } from "../../shared/models/user.model";

@Injectable()
export class UserProGuard implements CanActivate {

  public user: User;
  public userType: string;

  constructor(private router: Router, private jwtAuth: JwtAuthService, private snack: MatSnackBar, public local: LocalStoreService ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.user = this.local.getItem('user');
    this.userType = this.local.getItem('user.userType'); 

    if (this.userType == "PROFESSIONNEL"){
        return true;
    } else {
        this.snack.open('Ce service est accessible seulement aux professionnels', 'OK');
        return false;
    }
  }
}
