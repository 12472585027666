<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu">
    <span class="flag-icon {{currentLang.flag}} mr-8"></span>
    <span>{{currentLang.name}}</span>
  </button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
        <span class="flag-icon mr-8 {{lang.flag}}"></span>
        <span>{{lang.name}}</span>
  </button>
</mat-menu>


  <egret-search-input-over placeholder="Ville : exemple Paris" resultPage="/search">
  </egret-search-input-over>
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
  >
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">0</span>
  </button>
  <!-- Top left user menu -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
    <img src="assets/images/avatars/001-man.svg" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
