import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubscriptionDB } from '../../shared/inmemory-db/subscriptions';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { LocalStoreService } from "./local-store.service";
import { Tracking } from '../models/Tracking.model';


@Injectable()
export class TrackingService {

  public owner: string;
  private baseUrl = '';
  items: any[];

  private firstSource = new BehaviorSubject<string>('YES');
  first = this.firstSource.asObservable();


  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.owner = this.local.getItem('user.id');
    let subscriptionDB = new SubscriptionDB();
    this.items = subscriptionDB.subscriptions;
  }
  
  addTracking(item){
    item.owner = this.owner;
    this.http.post(this.local.getItem('url.base')+'/tracking/save', item)
                    .subscribe(res => console.log(res ));
  }

}
